import styled from "styled-components";
import sectionBgImage from "../../assets/images/bg/h1_banner_bg.png";

const NFTMintWrapper = styled.section`
    .nft_container {
        background: url(${sectionBgImage});

        padding-bottom: 5%;
        padding-left: 2%;
        padding-top: 12%;
    }
    .nft_content {
        display: flex;
        
        .right_content {
            padding-left: 5%;
            .asset_name {
                font-weight: 400;
                font-size: 36px;
                color: white;
            }
            .asset_price {
                font-weight: 300;
                font-size: 26px;
                color: white;
            }
        }
    }
    .nft_image {
        width: 40%;
        height:60%;
        .nft-video {
            width: 100%;
            height: 100%;
        }

        img {
            width: 90%;
            height: 90%;
        }
    }
    .nft_group {
        label {
            color : white;
        }
    }
    .mint_content {
        margin-top: 2%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button-63 {
        align-items: center;
        background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
        border: 0;
        border-radius: 8px;
        box-sizing: border-box;
        color: #FFFFFF;
        box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
        font-family: Phantomsans, sans-serif;
        display: flex;
        justify-content: center;
        font-size: 20px;
        max-width: 100%;
        min-width: 140px;
        line-height: 1em;
        text-decoration: none;
        padding: 19px 24px;
        -webkit-user-select: none;
        user-select: none;
        white-space: nowrap;
        touch-action: manipulation;
        cursor: pointer;
    }

    .button-63:active,
    .button-63:hover {
        outline: 0;
    }
    .mint_button_container {
        text-align:center;
        padding-top: 3%;

        .input_text {
            text-align: center;
            p {
                font-size: 18px;
                font-weight: 200;
                padding-right: 2%;
                color: white;
            }
            input {
                position: relative;
                width: 30%;
                text-align: center;

            }
        }
    }
    .label_group {
        display: none;
    }
    .address_group {
        width: 100%;
        margin-top: 1%;

        label {
            width: 60%;
            text: align: center;
        }
        input {
            width: 40%;
            height: 40px;
            border-radius : 5px;
            border : none;
            font-weight : 500;
            text-align: center;
            font-size: 20px;
            &: focus {
                outline: none;
            }
        }
    }
    
    .withdraw_content {
        margin-top: 1%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .address_inputs label {
        margin-right: 10px;
        color : white; 
        font-size: 20px;
    }

`;

export default NFTMintWrapper;
