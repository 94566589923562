import MintWrapper from '../mint/Mint.style'
import axios from "axios";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import ReactPaginate from 'react-paginate';

import BACKEND_URL from "../../config/config"

const Minted = () => {

  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(12);
  const [pageCount, setPageCount] = useState(1);
  const currentPage = useRef();
  const [flag, setFlag] = useState('');

  const handlePageClick = (event) => {
    currentPage.current = event.selected + 1;
    getPaginationData();
    window.scrollTo(0, 0);
  }

  function getPaginationData() {
    const fetchData = async () => {
      try {
        const nfts = await axios.get(`${BACKEND_URL}/getminted?page=${currentPage.current}&limit=${limit}`)
          .then((response) => {
            setData(response.data.resultData);
            setFlag(response.data);
            setPageCount(response.data.pageCount);
          })
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  };

  useEffect(() => {
    currentPage.current = 1;
    getPaginationData();
  }, []);

  return (
    <MintWrapper className='mint'>
      <div className='nft_container'>
        {flag == "No" ? <h2>Minted NFT is not exist.</h2>   :
          data.map((item, index) => (
            <div className='nft_item' key={index}>
              <Link href={`/mint/${item.ipfs}`}>
                <video key={item.ipfs} className='nft-video' playsInline loop autoPlay muted>
                  <source src={item.url} type="video/mp4" />
                </video>
              </Link>
              <p className='nft_name'>{item.name}</p>
            </div>
          ))}
      </div>
      <div className='pagination-button'>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< Prev"
          renderOnZeroPageCount={null}
          marginPagesDisplayed={2}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
        />
      </div>
    </MintWrapper>
  );
};

export default Minted;
