import styled from "styled-components";

const NavWrapper = styled.nav`
  z-index: 999;
  &.bithu_header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 1px;
    height: 90px;
    transition: all 0.3s;

    &.sticky {
      position: fixed;
      top: 0;
      width: 100%;
      background: rgba(27, 34, 38, 0.8);
      backdrop-filter: blur(15px);
      z-index: 998;
      margin-top: 0px;
      transition: all 0.2s;
    }
  }

  .bithu_menu_sect {
    height: 90px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bithu_menu_left_sect {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 15%;

    .logo {
      a {
        display: inline-block;
      }
    }
  }

  .login_signup {
    margin-left: 10px;
    width: 45px;
    margin-right: 10px;
    a {
      font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
    }

    &:hover {
      a {
        color: rgb(255, 59, 255);
      }
    }
  }

  .login_select {
    display: inline-flex;
  }

  .logout_button {
    font-family: "Bakbak One";
    background-color: rgba(0,0,0,0);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }

  .logout_button: hover {
    color: rgb(255, 59, 255);
  }

  .bithu_menu_right_sect {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .bithu_menu_list {
    margin-left: 65px;
    margin-right: 96px;
    max-width: 514px;
    min-width: 409px;
    width: 100%;
    ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;

      li {
        position: relative;
        cursor: pointer;

        a {
          font-family: "Bakbak One";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
        }

        &:hover {
          a {
            color: rgb(255, 59, 255);
          }
        }

        /* submenu */
        &.submenu {
          .sub_menu_sect {
            background: transparent;
            border-top: 50px solid transparent;
            position: absolute;
            top: -50px;
            left: -20px;
            width: 190px;
            visibility: hidden;
            opacity: 0;
            z-index: -100;
            transition: all 0.5s;

            .sub_menu_list {
              padding: 15px 20px;
              background: rgba(255, 59, 255, 0.7)!important;
              flex-wrap: wrap;
              li {
                width: 80%;
                a {
                  font-family: "Bakbak One";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 40px;
                  color: rgba(255, 255, 255, 0.8);
                  text-transform: capitalize;
                }

                &:hover {
                  a {
                    color: rgb(255, 59, 255);
                  }
                }
              }
            }
          }

          &:hover {
            .sub_menu_sect {
              top: 7px;
              visibility: visible;
              opacity: 1;
              z-index: 99;
            }
          }
        }
      }
    }
  }
  .amount_neoxa{
    color: black;
    width: 135px;
    margin-right: 10px;
    text-align:center;
    background-color: rgb(255, 59, 255);
    border-radius : 20px;
    font-weight: 700;
    // padding-left: 20px;
    // padding-right: 20px;
  }
  .header_left{
    display: inline-flex;

  }
  .logout_menu_list {
    margin-left: 30px;
    margin-right: 96px;
    max-width: 50px;
    min-width: 40px;
    width: 60%;
    display: inline-flex;
    
    ul {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;

      li {
        position: relative;
        cursor: pointer;

        a {
          font-family: "Bakbak One";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
        }

        &:hover {
          a {
            color: rgb(255, 59, 255);
          }
        }

        /* submenu */
        &.logout_submenu {
          .sub_menu_sect {
            background: transparent;
            border-top: 50px solid transparent;
            position: absolute;
            top: -50px;
            left: -20px;
            width: 300px;
            visibility: hidden;
            opacity: 0;
            z-index: -100;
            transition: all 0.5s;

            .sub_menu_list {
              padding: 10px 20px;
              background: rgba(255, 59, 255, 0.7)!important;
              flex-wrap: wrap;
              li {
                width: 100%;
                a {
                  font-family: "Bakbak One";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 40px;
                  color: rgba(255, 255, 255, 0.8);
                  text-transform: capitalize;
                }
                button {
                  font-family: "Bakbak One";
                  background-color: rgba(0,0,0,0);
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 40px;
                  color: rgba(255, 255, 255, 0.8);
                  text-transform: capitalize;
                }

                &:hover {
                  a {
                    color: rgb(255, 59, 255);
                  }
                }
              }
            }
          }

          &:hover {
            .sub_menu_sect {
              top: 7px;
              visibility: visible;
              opacity: 1;
              z-index: 99;
            }
          }
        }
      }
    }
  }

  .bithu_menu_btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    min-width: 120px;
    button {
      color: #ffffff;
      text-transform: uppercase;
      font-family: "Bakbak One";
      font-weight: 400;
      font-size: 16px;
    }

    .menu_btn {
      display: none;
      border: none;
      background: transparent;
      cursor: pointer;
      svg {
        font-size: 40px;
      }
    }

    .join_btn {
      height: 50px;
      width: 114px;
      background-color: transparent;
    }

    .connect_btn {
      height: 50px;
      min-width: 150px;
      border: none;
      margin-left: 20px;
      background: rgba(255, 255, 255, 0.2);

      svg {
        font-size: 20px;
      }
    }
  }

  .iekbcc0.ju367va.ju367v1n{
    height: 50px;
    min-width: 150px;
    border: none;
    margin-left: 20px;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    &._12cbo8i3:hover{
      transform: scale(1) !important;
    }
    button{
      background: transparent;
      border-color: transparent;
      box-shadow: none;
    }
    .ju367v9x{
      background: transparent;
      border-color: transparent;
    }
  }

  @media (max-width: 1195px) {
    .bithu_menu_list {
      margin-right: 10px;
    }
    .bithu_menu_btns {

    }
  }

  @media (max-width: 1024px) {
    .bithu_menu_list {
      margin-right: 20px;
    }
  }
  @media (max-width: 991px) {
    .bithu_menu_right_sect {
      justify-content: end;
    }
    .bithu_menu_btns {
      justify-content: end;
      .menu_btn {
        display: block;
      }
    }

    .bithu_menu_btns {
      .join_btn {
        display: none;
      }
    }
    .bithu_menu_list {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
  }

  @media (max-width: 667px) {
    .bithu_menu_btns {
      .connect_btn {
        display: none;
      }

      .menu_btn {
        svg {
          font-size: 30px;
        }
      }
    }
  }

  @media (max-width: 540px) {
    .bithu_menu_left_sect {
      width: 180px;
      .logo {
        img {
          width: 100px;
        }
      }
    }

    .bithu_menu_right_sect {
      width: 50%;
      .bithu_menu_right_sect {
        width: 50%;
      }
    }
  }
`;

export default NavWrapper;
