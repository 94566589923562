import Layout from "../../common/layout";
import Login from "../../components/auth/login";

const LoginPage = () => {
  

  return (
    <>
      <Layout>
         <Login />
      </Layout>
    </>
  );
};

export default LoginPage;
