import LoginWrapper from "./Login.style";
import particleShape1 from "./../../../assets/images/icon/star_1.svg";
import particleShape2 from "./../../../assets/images/icon/star_2.svg";
import particleShape3 from "./../../../assets/images/icon/star_3.svg";
import particleShape4 from "./../../../assets/images/icon/star_4.svg";
import particleShape5 from "./../../../assets/images/icon/star_5.svg";
import particleShape6 from "./../../../assets/images/icon/star_6.svg";
import particleShape7 from "./../../../assets/images/icon/star_7.svg";
import logo from "./../../../assets/images/logo.png";
import axios from "axios";
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useNavigate } from 'react-router-dom';
import AuthContext from "../../../contexts/AuthContext";
import { Link } from "react-router-dom";

import BACKEND_URL from "../../../config/config"

const Login = () => {
  const particleShapes = [
    particleShape6,
    particleShape3,
    particleShape1,
    particleShape7,
    particleShape4,
    particleShape2,
    particleShape5,
  ];

  let navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if(!formData.username || !formData.password){
        toast.error('Please input the Username or password.', {autoClose: 700});
      }
      const { data } = await axios.post(`${BACKEND_URL}/login`, formData)
      if (data.error) {
        return toast.error(data.error);
      } else {
        setFormData({});
        toast.success("Successfully Login", {autoClose: 700});
        login(data);
        navigate('/');
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <LoginWrapper className="login_wrapper">
      {/* <ToastContainer /> */}
      <div className="login-container">
        <div className="main-container">
          <div className="">
            <div className="bithu_menu_left_sect">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="bithu nft logo" />
                </Link>
              </div>
            </div>
          </div>
          <h1 className="text_h1">Sign in to your account</h1>
          <div className="bithu_v1_cta_content">
            <div className="footer_stras_sect">
              <div className="footer_stars">
                {particleShapes?.map((shape, i) => (
                  <span key={i} className={`star_${i + 1}`}>
                    <img src={shape} alt="bithu nft background particle" />
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="login-wrap">
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="sign-in-htm">
                <div className="group">
                  <label htmlFor="email" className="label"> Username </label>
                  <input id="email" type="username" name="username" className="input" onChange={handleInputChange} value={formData.username}></input>
                </div>
                <div className="group">
                  <label htmlFor="password" className="label">Password</label>
                  <input id="password" type="password" name="password" className="input" data-type="password" onChange={handleInputChange} value={formData.password}></input>
                </div>
                <div className="group">
                  <input type="submit" className="button" value="Sign In"></input>
                </div>
                <div className="hr"></div>
                <div className="foot-lnk">
                  <Link to="/forgotpassword">Forgot Password?</Link>
                  <div className="create_account">
                    <Link to="/signup">Create an account</Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LoginWrapper>
  );
};

export default Login;
