import { useParams } from "react-router-dom";
import GlobalStyles from "../../assets/styles/GlobalStyles";
import Header from "../../components/section/header/v1/Header";
import Layout from "../../common/layout";
import Footer from "../../components/section/footer/v1";
import NFTTiers from "../../components/nfttiers/Tier"
import HowToMint from "../../components/section/howToMint/v1";

const NFTItemPage = () => {
    const {mintTier}= useParams();

    return (
        <Layout>
            <GlobalStyles />
            <Header />
            <NFTTiers tier = {mintTier}/>
            <Footer />
        </Layout>
    );
};

export default NFTItemPage;
