import styled, { keyframes } from "styled-components";
import sectionBgImage from "../../../assets/images/bg/h1_banner_bg.png";

const ForgotPasswordWrapper = styled.section`
  .forgotpass-container{
    background: url(${sectionBgImage});
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10% 10% 5%;

  }

  .text_h1 {
    color: white;
  }

  .container {
    position: relative;
    top: 20%;
    text-align: center;
  }
  
  .forgotpass-wrap{
    width:100%;
    margin:auto;
    margin-top: 2%;
    max-width:525px;
    min-height:450px;
    position:relative;
  }
  .forgotpass-html{
    width:100%;
    height:100%;
    position:absolute;
    padding:90px 70px 50px 70px;
  }
  
  .forgotpass-html .sign-in,
  .forgotpass-html .sign-up,
  .forgotpass-form .group .check{
    display:none;
  }
  .forgotpass-html .tab,
  .forgotpass-form .group .label,
  .forgotpass-form .group .button{
    text-transform:uppercase;
  }
  .forgotpass-html .sign-in:checked + .tab,
  .forgotpass-html .sign-up:checked + .tab{
    color:#fff;
    border-color:#1161ee;
  }
  .forgotpass-form{
    min-height:345px;
    position:relative;
    perspective:1000px;
    transform-style:preserve-3d;
  }
  .forgotpass-form .group{
    margin-bottom:30px;
  }
  .forgotpass-form .group .label,
  .forgotpass-form .group .input,
  .forgotpass-form .group .button{
    width:100%;
    color:#fff;
    display:block;
  }
  .forgotpass-form .group .input,
  .forgotpass-form .group .button{
    border:none;
    padding:15px 20px;
    border-radius:25px;
    background:rgba(255,255,255,.1);
  }
  .forgotpass-form .group input[data-type="password"]{
    text-security:circle;
    -webkit-text-security:circle;
  }
  .forgotpass-form .group .label{
    color:#aaa;
    font-size:16px;
  }
  .forgotpass-form .group .button{
    background:#1161ee;
  }
  .forgotpass-form .group label .icon{
    width:15px;
    height:15px;
    border-radius:2px;
    position:relative;
    display:inline-block;
    background:rgba(255,255,255,.1);
  }
  .forgotpass-form .group label .icon:before,
  .forgotpass-form .group label .icon:after{
    content:'';
    width:10px;
    height:2px;
    background:#fff;
    position:absolute;
    transition:all .2s ease-in-out 0s;
  }
  .forgotpass-form .group label .icon:before{
    left:3px;
    width:5px;
    bottom:6px;
    transform:scale(0) rotate(0);
  }
  .forgotpass-form .group label .icon:after{
    top:6px;
    right:0;
    transform:scale(0) rotate(0);
  }
  .forgotpass-form .group .check:checked + label{
    color:#fff;
  }
  .forgotpass-form .group .check:checked + label .icon{
    background:#1161ee;
  }
  .forgotpass-form .group .check:checked + label .icon:before{
    transform:scale(1) rotate(45deg);
  }
  .forgotpass-form .group .check:checked + label .icon:after{
    transform:scale(1) rotate(-45deg);
  }
  .forgotpass-html .sign-in:checked + .tab + .sign-up + .tab + .forgotpass-form .sign-in-htm{
    transform:rotate(0);
  }
  .forgotpass-html .sign-up:checked + .tab + .forgotpass-form .sign-up-htm{
    transform:rotate(0);
  }
  
  .hr{
    height:2px;
    margin:30px 0 20px 0;
    background:rgba(255,255,255,.2);
  }
  .foot-lnk{
    text-align:center;
    display: inline-flex;
  }
  .create_account{
    margin-left: 70px;
  }
`;

export default ForgotPasswordWrapper;
