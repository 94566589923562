import styled from "styled-components";
import sectionBgImage from "../../assets/images/bg/h1_banner_bg.png";

const MyAssetsWrapper = styled.section`
    .myassets-container{
        background: url(${sectionBgImage});
        background-repeat: no-repeat;
        width: 100%;
        position: relative;
        padding-top: 10%;
        height: 100%;
        text-align: center;
    }
    .nft-container {
        margin-left: 10%;
        margin-right: 10%;
        margin-bottom: 5%;
        margin-top: 5%;
        width: 80%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items:center;
        gap: 3%;
    }
    .nft_item {
        text-align: center;
    }
    .nft_name {
        margin-bottom: 1%;
    }
    .address_inputs {
        position: relative;
        text-align: center;
    }
    .label_group {
        display: none;
    }
    .address_group {
        width: 100%;
        margin-top: 1%;

        label {
            width: 60%;
            text: align: center;
        }
        input {
            width: 40%;
            height: 40px;
            border-radius : 5px;
            border : none;
            font-weight : 500;
            text-align: center;
            font-size: 20px;
            &: focus {
                outline: none;
            }
        }
    }
    
    .withdraw_content {
        margin-top: 1%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .address_inputs label {
        margin-right: 10px;
        color : white; 
        font-size: 20px;
    }
    .myassets_title {
        color: white;
    }
`;

export default MyAssetsWrapper;
