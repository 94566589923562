import { useContext, useEffect, useState } from "react";
import { MdNotes } from "react-icons/md";
import NavWrapper from "./Header.style";
import MobileMenu from "../mobileMenu/MobileMenu";
import logo from "../../../../assets/images/logo.png";
import AuthContext from "../../../../contexts/AuthContext";
import axios from "axios";
import { Link } from "react-router-dom";

import BACKEND_URL from "../../../../config/config"

const Header = () => {
  // const { walletModalHandle } = useModal();
  const [isMobileMenu, setMobileMenu] = useState(false);
  const { isLoggedIn, setIsLoggedIn, logout } = useContext(AuthContext);
  const [balance, setBalance] = useState('');
  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };
  const handlelogout = async () => {
    logout();
    window.location.assign("/");
  }
  useEffect(() => {
    const header = document.getElementById("navbar");

    const handleScroll = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });
    const loggedInUser = localStorage.getItem("userData");
    if (loggedInUser) {
      setIsLoggedIn(JSON.parse(loggedInUser));
    }

    const formData = JSON.parse(loggedInUser);
    axios.post(`${BACKEND_URL}/getbalance`, formData).then((response) => {
      setBalance(response.data.balance);
    })
    return () => {
      window.removeEventListener("sticky", handleScroll);
    };
  }, []);

  return (
    <>
      <NavWrapper className="bithu_header" id="navbar">
        <div className="container">
          {/* Main Menu Start */}
          <div className="bithu_menu_sect">
            <div className="bithu_menu_left_sect">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="bithu nft logo" />
                </Link>
              </div>
            </div>
            <div className="bithu_menu_right_sect bithu_v1_menu_right_sect">
              <div className="bithu_menu_list">
                <ul>
                  <li>
                    <a href="#home">Home</a>
                  </li>
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="#roadmap">Roadmap</a>
                  </li>
                  <li>
                    <a href="#team">Team</a>
                  </li>
                  <li>
                    <a href="#faq">FAQ</a>
                  </li>
                  {isLoggedIn &&
                    <li className="submenu">
                      <a href="# ">Pages +</a>
                      <div className="sub_menu_sect">
                        <ul className="sub_menu_list">
                          <li>
                            <Link to="/minted">Minted</Link>
                          </li>
                          <li>
                            <Link to="/nft/heroes">Heroes</Link>
                          </li>
                          <li>
                            <Link to="/nft/nodiacs">Nodiacs</Link>
                          </li>
                          <li>
                            <Link to="/nft/omens">Omens</Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  }
                </ul>
              </div>
              <div className="bithu_menu_btns">
                <button className="menu_btn" onClick={() => handleMobileMenu()}>
                  <MdNotes />
                </button>
              </div>
              {isLoggedIn ? (
                <div className="header_left">
                  <div className="amount_neoxa">{balance} neox </div>
                  <div className="logout_menu_list">
                    <ul>
                      <li className="logout_submenu">
                        <a href="# ">Account</a>
                        <div className="sub_menu_sect">
                          <ul className="sub_menu_list">
                            <li>
                              <Link to="/deposit">Deposit NEOXA</Link>
                            </li>
                            <li>
                              <Link to="/withdraw">Withdraw NEOXA</Link>
                            </li>
                            <li>
                              <Link to="/mint">Mint NFT</Link>
                            </li>
                            <li>
                              <Link to="/deponft">Deposit NFT</Link>
                            </li>
                            {/* <li>
                              <Link to="/withnft">Withdraw NFT</Link>
                            </li> */}
                            <li>
                              <Link to="/myassets">My Assets</Link>
                            </li>
                            <li>
                              <Link href="" onClick={() => handlelogout()}>LogOut</Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="login_select">
                  <div className="login_signup">
                    <Link to="/login">Login</Link>
                  </div>
                  <div className="login_signup">
                    <Link to="/signup">SignUp</Link>
                  </div>
                </div>
              )}

            </div>
          </div>
          {/* <!-- Main Menu END --> */}
        </div>
      </NavWrapper>
      {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
    </>
  );
};

export default Header;
