import Layout from "../../common/layout";
import WithdrawNFT from "../../components/withdraw/WithdrawNFT";
import GlobalStyles from "../../assets/styles/GlobalStyles";
import Header from "../../components/section/header/v1/Header";
import Footer from "../../components/section/footer/v1";
import { useParams } from "react-router-dom";

const WithdrawNFTPage = () => {
    const {id}= useParams();

    return (
        <Layout>
            <GlobalStyles />
            <Header />
            <WithdrawNFT id = {id}/>
            <Footer />
        </Layout>
    );
};

export default WithdrawNFTPage;
