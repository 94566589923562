import { useEffect } from 'react';
import NFTMintWrapper from './NFTMint.style'
import { useState } from 'react';
import axios from "axios";

import BACKEND_URL from "../../config/config"

const NFTMint = (props) => {
    const { id } = props;
    const [item, setItem] = useState([]);
    const [address, setAddress] = useState('');
    const [username, setUsername] = useState('');
    useEffect(() => {

        const loggedInUser = localStorage.getItem("userData");
        if (loggedInUser) {
            setAddress(JSON.parse(loggedInUser).address);
            setUsername(JSON.parse(loggedInUser).username);
        }
        // Get transactions
        axios.post(`${BACKEND_URL}/getonenft`, { 'id': id }).then((response) => {
            setItem(response.data);
        });
    }, [id]);

    const handleMint = () => {
        const data = {
            ...item,
            address,
            username,
        }

        axios.post(`${BACKEND_URL}/mint`, data).then((response) => {
        });
    }

    return (
        <NFTMintWrapper className='mint'>
            <div className='nft_container'>
                <div className="container">
                    <div className="nft_content">
                        <div className="nft_image">
                            <video key={item.ipfs} className='nft-video' playsInline loop autoPlay muted>
                                <source src={item.url} type="video/mp4" />
                            </video>
                        </div>
                        <div className="right_content">
                            <div className="nft_group">
                                <label htmlFor="">- Name : </label>
                                <p className="asset_name">{item.name}</p>
                            </div>
                            <div className="nft_group">
                                <label htmlFor="">- Price : </label>
                                <p className="asset_price">{item.price} neox</p>
                            </div>
                            <div className="nft_group">
                                <label htmlFor="">- Owner : </label>
                                {item.ownerAddress ? (<p className="owner_address">{item.ownerAddress}</p>) : (<p>Not exist</p>)}
                            </div>
                            <div className="nft_group">
                                <label htmlFor="">- IPFS : </label>
                                <p className="asset_price">{item.ipfs}</p>
                            </div>
                        </div>
                    </div>
                    {!item.ownerAddress &&
                        <div className="mint_button_container">
                            <div className="mint_content">
                                <button className="button-63" onClick={handleMint} value="Mint Now">Mint Now</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </NFTMintWrapper>
    );
};

export default NFTMint;
