import GlobalStyles from "../../assets/styles/GlobalStyles";
import Header from "../../components/section/header/v1/Header";
import Layout from "../../common/layout";
import Banner from "../../components/section/banner/v1";
import Counter from "../../components/section/counter/v1";
import CharacterSlider from "../../components/section/characterSlider/v1";
import HowToMint from "../../components/section/howToMint/v1";
import Footer from "../../components/section/footer/v1";
import Mint from "../../components/mint";

const MintPage = () => {
  
  return (
    <Layout>
      <GlobalStyles />
      <Header />
      <Banner />
      <Mint />
      <Footer />
    </Layout>
  );
};

export default MintPage;
