import Layout from "../../common/layout";
import ForgotPassword from "../../components/auth/forgotpassword/ForgotPassword";

const ForgotPasswordPage = () => {
  

  return (
    <>
      <Layout>
         <ForgotPassword />
      </Layout>
    </>
  );
};

export default ForgotPasswordPage;
