import thumb1 from "../images/nft/mint-item_card_img1.png";
import thumb2 from "../images/nft/mint-item_card_img2.png";
import thumb3 from "../images/nft/mint-item_card_img3.png";

const data = [
  {
    id: 1,
    price : "23500.00",
    thumb: thumb1,
    name: "NAME1",
    featuredText: "",
    title: "Single Mint",
    accessText: "",
    unitPrice: "0.08 ETH",
    totalPrice: "0.08 ETH",
    btnText: "Mint 1x",
    btnOnClick: "1",
  },
  {
    id: 2,
    price : "533300.00",
    thumb: thumb2,
    name: "NAME2",
    featuredText: "Popular",
    title: "3x Mint",
    accessText: "VIP channel access",
    unitPrice: "0.09 ETH",
    totalPrice: "0.27 ETH",
    btnText: "Mint 3x",
    btnOnClick: "3",
  },
  {
    id: 3,
    price : "1111111.00",
    thumb: thumb3,
    name: "NAME3",
    featuredText: "Best Deal",
    title: "5x Mint",
    accessText: "VIP channel access",
    unitPrice: "0.12 ETH",
    totalPrice: "0.36 ETH",
    btnText: "Mint 5x",
    btnOnClick: "5",
  },
  {
    id: 4,
    price : "3333300.00",
    thumb: thumb1,
    name: "NAME4",
  },
  {
    id: 5,
    price : "533232300.00",
    thumb: thumb2,
    name: "NAME5",
  },
  {
    id: 6,
    price : "500.00",
    thumb: thumb1,
    name: "NAME6",
  },
  {
    id: 7,
    price : "502323230.00",
    thumb: thumb3,
    name: "NAME7",
  },
];

export default data;
