import { useParams } from "react-router-dom";
import GlobalStyles from "../../assets/styles/GlobalStyles";
import Header from "../../components/section/header/v1/Header";
import Layout from "../../common/layout";
import Footer from "../../components/section/footer/v1";
import AssetItems from "../../components/myassets/myassetItems/myAssetItems";

const AssetItemsPage = () => {
    const {assetid}= useParams();
    return (
        <Layout>
            <GlobalStyles />
            <Header />
            <AssetItems id = {assetid}/>
            <Footer />
        </Layout>
    );
};

export default AssetItemsPage;
