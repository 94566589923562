import { useState } from "react";
import axios from 'axios';
import SignUpWrapper from "./SignUp.style";
import logo from "../../../assets/images/logo.png";
import particleShape1 from "../../../assets/images/icon/star_1.svg";
import particleShape2 from "../../../assets/images/icon/star_2.svg";
import particleShape3 from "../../../assets/images/icon/star_3.svg";
import particleShape4 from "../../../assets/images/icon/star_4.svg";
import particleShape5 from "../../../assets/images/icon/star_5.svg";
import particleShape6 from "../../../assets/images/icon/star_6.svg";
import particleShape7 from "../../../assets/images/icon/star_7.svg";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import BACKEND_URL from "../../../config/config"

const SignUp = () => {
  const particleShapes = [
    particleShape6,
    particleShape3,
    particleShape1,
    particleShape7,
    particleShape4,
    particleShape2,
    particleShape5,
  ];

  let navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
    confirm_password: ''
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.username)
        return toast.error("Please iuput the username address!", {autoClose: 700});
      if (!formData.password)
        return toast.error("Please input the password!", {autoClose: 700});
      if (formData.confirm_password !== formData.password)
        return toast.error("Please match the password!", {autoClose: 700});
      else {
        const { data } = await axios.post(`${BACKEND_URL}/signup`, formData)
        if (data.error) {
          return toast.error(data.error, {autoClose: 700});
        } else {
          toast.success('Your account is created!', {autoClose: 700});
          navigate('/login');
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <SignUpWrapper className="signup_info">
      {/* <ToastContainer /> */}
      <div className="signup-container">
        <div className="container">
          <div className="">
            <div className="bithu_menu_left_sect">
              <div className="logo">
                <Link to="/">
                    <img src={logo} alt="bithu nft logo" />
                </Link>
              </div>
            </div>
          </div>
          <h1 className="text_h1">Create your account</h1>
          <div className="bithu_v1_cta_content">
            <div className="footer_stras_sect">
              <div className="footer_stars">
                {particleShapes?.map((shape, i) => (
                  <span key={i} className={`star_${i + 1}`}>
                    <img src={shape} alt="bithu nft background particle" />
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="signup-wrap">
            <form className="signup-form" onSubmit={handleSubmit}>
              <div className="sign-in-htm">
                <div className="group">
                  <label htmlFor="username" className="label">Username</label>
                  <input id="email" type="text" name="username" className="input" onChange={handleInputChange} value={formData.username}></input>
                </div>
                <div className="group">
                  <label htmlFor="password" className="label">Password</label>
                  <input id="password" name="password" type="password" className="input" onChange={handleInputChange} value={formData.password}></input>
                </div>
                <div className="group">
                  <label htmlFor="confirm_password" className="label">Confirm Password</label>
                  <input id="confirm_password" type="password" name="confirm_password" className="input" data-type="password" onChange={handleInputChange} value={formData.confirm_password}></input>
                </div>
                <div className="group">
                  <input type="submit" className="button signup_button" value="Sign Up"></input>
                </div>
                <div className="hr"></div>
                <div className="foot-lnk">
                  <Link to="/login">Already have an account?</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </SignUpWrapper>
  );
};

export default SignUp;
