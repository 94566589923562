import Counter from "../../../../common/counter";

import CoinInfoCounterWrapper from "./Counter.style";

const CoinInfoCounter = () => {

  return (
    <CoinInfoCounterWrapper>
      <div className="container">
        <ul>
            <li>
              <h3> 250 </h3>
              <h3>TOTAL NFTS</h3>
            </li>
            <li>
              <h3> 100 </h3>
              <h3>TOTAL OWNERS</h3>
            </li>
            <li>
              <h3> 20 </h3>
              <h3>NODEXA NODES</h3>
            </li>
        </ul>
      </div>
    </CoinInfoCounterWrapper>
  );
};

export default CoinInfoCounter;
