import styled from "styled-components";
import sectionBgImage from "../../assets/images/bg/h1_banner_bg.png";

const DepositWrapper = styled.section`
    .deposit_container{
        position: relative;
        background: url(${sectionBgImage});
        background-repeat: no-repeat;
        width: 100%;
        padding: 10% 10% 5%;
    }
    .top_content_text {
        position: relative;
        width: 100%;
        padding: 1% 10% 1% 10%;
        p {
            font-size: 20px;
        }
    }
    .top_content_header_title {
        font-size: 50px;
        font-weight: 700;
        text-align: center;
    }
    .v1_roadmap_divider {
        text-align: center;
    }
    .content {
        padding-top: 3%;
        width: 100%;
        display: inline-flex;
        .content_left {
            position: relative;
            width: 40%;

            .title_address {
                font-size: 25px;
                text-align: center;
                color: #66a3ff;
            }

            .account_address{
                text-align : center;
            }
            .account_address input {
                width: 90%;
                height: 40px;
                border-radius : 5px;
                border : none;
                font-weight : 500;
                text-align: center;
                font-size: 20px;
                &: focus {
                    outline: none;
                }
            }
            .copy_clipboard {
                color: white;
                margin-left: 3px;
                cursor: pointer;
            }
            .qr_code_address {
                position: relative;
                margin-top : 30px;
                text-align : center;

                .qr_code {
                    height: 200px!important;
                    width: 200px!important;
                }
            }
            .notice_address {
                color : white;
                position: relative;
                margin-top: 50px;
            }

            .bottom_address {
                margin-top: 30px;
                border-top: 1px solid white;

                .current_balance {
                    width: 100%;
                    text-align: center;
                    padding-top: 20px;
                    color: #66a3ff;
                }
            }
        }
        .content_right {
            position: relative;
            text-align: center;
            width: 60%;
            color: white;
            padding-left: 2%;
            padding-right: 2%;

            .transacrion_history {
                position: relative;
                width: 100%;
                height: 70%;
                overflow-y: auto;
            }

            .table_div {
                width: 99%;
                padding-bottom: 1%;
            }
            .transactions_table {
                border : 1px;
                border-collapse: collapse;
                text-align: center;
                width: 100%;

                td {
                    border : 1px solid  #b3b3b3;
                    padding : 2px 4px 1px 4px;
                }
                .trsansaction_address_td {
                    max-width: 250px;
                    overflow: hidden;
                }
            }
        }
    }

`;

export default DepositWrapper;
