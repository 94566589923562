import { useState, useEffect } from "react";
import DepositWrapper from "./Deposit.style";
import QRCode from "qrcode.react";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import dividerImgShape from "../../assets/images/nft/v1_roadmap_divider_shape.svg";
import { FaCopy } from 'react-icons/fa';
import axios from "axios";
import BACKEND_URL from "../../config/config"
let transactions = [];

const Deposit = () => {
  const [address, setAddress] = useState('');
  const [balance, setBalance] = useState('');
  const [transaction, setTransaction] = useState([]);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("userData");
    if (loggedInUser) {
      setAddress(JSON.parse(loggedInUser).address);
    }
    // Get transactions
    const formData = JSON.parse(loggedInUser);
    axios.post(`${BACKEND_URL}/gettransaction`, formData).then((response) => {
      transactions = (response.data);
      setTransaction(transactions)
    })
    axios.post(`${BACKEND_URL}/getbalance`, formData).then((response) => {
      setBalance(response.data.balance);
    })
  }, []);

  const copyClipClicked = () => {
    toast.success("Copied.", { autoClose: 700 });
  }
  return (
    <DepositWrapper className="deposit">
      <div className="deposit_container">
        <div className="top_content">
          <div className="top_content_header">
            <p className="top_content_header_title">NEOXA DEPOSIT</p>
          </div>
          <div className="top_content_text">
            <p>The transfer of Neox to your NEOXA Wallet is subject to individual wallet creation processes. Please be informed that the transfer will be credited to your account after 30 confirmations have been successfully completed. Kindly note that the confirmation process may take between 15 to 30 minutes upon completion of the transfer.
            </p>
          </div>
        </div>
        <div className="v1_roadmap_divider">
          <img src={dividerImgShape} alt="bithu nft section divider" />
        </div>
        <div className="content">
          <div className="content_left">
            <p className="title_address"> Your NEOXA Deposit Address</p>
            <div className="account_address">
              <input type="text" value={address || ''} readOnly />
              <FaCopy className="copy_clipboard" onClick={copyClipClicked} />
            </div>
            <div className="qr_code_address">
              <QRCode value={address} className="qr_code" />
            </div>
            <div className="bottom_address">
              <div className="current_balance">
                <h2>Current Balance {balance} NEOX</h2>
              </div>
              <div className="notice_address">
                <h3>Important</h3>
                <p className="bottom_text">* Send only NEOX to this deposit address</p>
                <p className="bottom_text">* Sending coins or tokens other than NEOX to this address may result in the loss of your deposit.</p>
                <p className="bottom_text">* NEOX will be deposited after 30 network confirmations.</p>
                <p className="bottom_text">* After your payment is approved, visit this page again.</p>
              </div>
            </div>
          </div>

          <div className="content_right">
            <div className="transacrion_history">
              <div className="table_div">
                <table className="transactions_table">
                  <thead>
                    <tr>
                      <th>*</th>
                      <th>TX</th>
                      <th>Amount</th>
                      <th>Confirmation</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transaction?.map((item, id) => (
                    <tr>
                      <td>{id + 1}</td>
                      <td className="trsansaction_address_td">{item.txid}</td>
                      <td>{item.amount}</td>
                      <td>{item.confirmations}</td>
                      <td>{item.category}</td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DepositWrapper>
  );
};

export default Deposit;
