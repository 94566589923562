import Layout from "../../common/layout";
import SignUp from "../../components/auth/signup";

const SignUpPage = () => {
  

  return (
    <>
      <Layout>
         <SignUp />
      </Layout>
    </>
  );
};

export default SignUpPage;
