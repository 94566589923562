import { Routes, Route } from "react-router-dom";
import HomeV1 from "../pages/homeV1";
import HomeV2 from "../pages/homeV2";
import HomeV3 from "../pages/homeV3";
import HomeV4 from "../pages/homeV4";
import HomeV5 from "../pages/homeV5";
import Blogs from "../pages/blogs";
import BlogDetails from "../pages/blogDetails";
import ComingSoon from "../pages/comingSoon";
import AboutPage from "../pages/about";
import RoadmapPage from "../pages/roadmap";
import Collections from "../pages/collections";
import ContactPage from "../pages/contact";
import MintPageOne from "../pages/mint-1";
import MintPageTwo from "../pages/mint-2";
import FAQPage from "../pages/faq";
import LoginPage from "../pages/auth/login";
import SignUpPage from "../pages/auth/signup";
import ForgotPasswordPage from "../pages/auth/forgotpassword";
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from "../contexts/AuthContext";
import DepositPage from "../pages/deposit/Deposit";
import WithdrawPage from "../pages/withdaw/Withdraw";
import MintPage from "../pages/mint/Mint";
import NFTItemPage from "../pages/mint/NFTItem";
import NFTTierPage from "../pages/mint/NFTTier"
import MintedPage from "../pages/mint/MintedPage";
import DepositNFTPage from "../pages/depositNFT/DepositNFT";
import WithdrawNFTPage from "../pages/withdrawNFT/WithdrawNFT";
import MyAssetsPage from "../pages/myassets/MyAssets";
import AssetItemsPage from "../pages/myassets/AssetItems";
function App() {
  return (
    <AuthProvider>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<HomeV1 />} exact />
        <Route path="/home-two" element={<HomeV2 />} />
        <Route path="/home-three" element={<HomeV3 />} />
        <Route path="/home-four" element={<HomeV4 />} />
        <Route path="/home-five" element={<HomeV5 />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/post" element={<BlogDetails />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/roadmap" element={<RoadmapPage />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/mint-1" element={<MintPageOne />} />
        <Route path="/mint-2" element={<MintPageTwo />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="/deposit" element={<DepositPage />} />
        <Route path="/withdraw" element={<WithdrawPage />} />
        <Route path="/mint" element={<MintPage />} />
        <Route path="/mint/:mintId" element={<NFTItemPage />} />
        <Route path="/nft/:mintTier" element={<NFTTierPage />} />
        <Route path="/minted" element={<MintedPage />}/>
        <Route path="/deponft" element={<DepositNFTPage />}/>
        <Route path="/withnft" element={<WithdrawNFTPage />}/>
        <Route path="/myassets" element={<MyAssetsPage />} />
        <Route path="/myassets/:assetid" element={<AssetItemsPage />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
