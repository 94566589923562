import GlobalStyles from "../../assets/styles/GlobalStyles";
import Header from "../../components/section/header/v1/Header";
import Layout from "../../common/layout";
import HowToMint from "../../components/section/howToMint/v1";
import Footer from "../../components/section/footer/v1";
import Minted from "../../components/minted";
import Banner from "../../components/section/banner/v1";

const MintedPage = () => {
  return (
    <Layout>
      <GlobalStyles />
      <Header />
      <Banner />
      <Minted />
      <Footer />
    </Layout>
  );
};

export default MintedPage;
