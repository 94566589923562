import { FaLinkedinIn, FaTwitter, FaInstagram } from "react-icons/fa";

import avatarThumb1 from "../../images/team/crew_avatar1.png";
import avatarThumb2 from "../../images/team/crew_avatar2.png";
import avatarThumb3 from "../../images/team/crew_avatar3.png";
import avatarThumb4 from "../../images/team/crew_avatar4.png";


const data = [
  {
    avatar: avatarThumb1,
    name: "Savvas",
    designation: "Founder",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb2,
    name: "Mykhailo B",
    designation: "Developer",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb3,
    name: "Stathis",
    designation: "",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb4,
    name: "Seedy",
    designation: "Designer",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  
];

export default data;
