import MyAssetsWrapper from "./MyAssets.style";
import { useEffect, useState } from "react";
import axios from 'axios';

import BACKEND_URL from "../../config/config"

const MyAssets = () => {

    const [myNFTs, setMyNFT] = useState([]);
    useEffect(() => {
        const loggedInUser = localStorage.getItem('userData');
        const formData = JSON.parse(loggedInUser);
        axios.post(`${BACKEND_URL}/getmynft`, formData).then((response) => {
            setMyNFT(response.data);
        })
    }, []);

    return (
        <MyAssetsWrapper>
            <div className="myassets-container">
                <h3 className="myassets_title"> How to Withdraw your NFT ?</h3>
                <div className="howto_withdeaw">
                    <p>Once click your NFT, you can go withdraw NFT page.</p>
                </div>
                <div className="nft-container">
                    {myNFTs?.map((item) => (
                        <a href={`/myassets/${item.ipfs_hash}`}>
                            <div className='nft_item'>
                                <img src={`https://cloudflare-ipfs.com/ipfs/${item.ipfs_hash}`} alt="" />
                                <p className='nft_name'>{item.name}</p>
                                <p className='nft_price'>Amount : {item.amount} </p>
                                {/* <p className='nft_price'>{item.ipfs_hash} </p> */}
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </MyAssetsWrapper>
    )
}

export default MyAssets;