import styled from "styled-components";
import mintBG from "../../assets/images/bg/counter-bg.png";

const DepositWrapper = styled.section`
    background: url(${mintBG});
    background-size: cover;
    text-align: center;
    h2 {
        padding-top: 1.5%;
        color : white;
    }
    .tier_button {
        display: inline-flex;
        width: 40%;
        padding-top: 2%;
        text-align: center;
        gap: 5%;
        .tiers {
            background-color: rgba(255, 59, 255, 0.6);
            width: 100%;
            height: 100%;
            border-radius: 10px;
            font-color: white;
            a {
                color: white;
                font-size: 20px;
                font-weight: 400;
                &:hover {
                      color: rgb(255, 0, 255);
                }
            }
        }
    }
    .nft_container {
        padding-top: 5%;
        margin-left: 10%;
        margin-right: 10%;
        width: 80%;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items:center;
        gap: 3%;
    }
    .nft_item {
        text-align: center;
        border-radius: 10px;
    }
    .nft_name {
        margin-top: 10%;
        margin-bottom: 1%;
        font-size: 20px;
    }
    .nft-video {
        border-radius: 10px;
        width: 90%;
        height: 90%;
        box-shadow: 0px 0 2px 0 rgba(255, 255, 255, 0.2), 1px 0px 12px 12px rgba(255, 255, 255, 0.2);

    }
    .pagination-button {
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 20px;
    }
    .minted_title {
        padding-top: 7%;
    }
`;

export default DepositWrapper;
