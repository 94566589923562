import { useState, useEffect } from "react";
import axios from 'axios';
import TierWrapper from "./Tier.style";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import BACKEND_URL from "../../config/config"

const Tier = (props) => {
    const { tier } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const nfts = await axios.post(`${BACKEND_URL}/getmonster`, { "tier": tier })
                    .then((response) => {
                        setData(response.data);
                    })
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [tier]);

    return (
        <TierWrapper className='tier'>
            <div className='nft_container'>
                {data?.map((item, index) => (
                    <div className='nft_item' key={index}>
                        <Link to={`/mint/${item.ipfs}`}>
                            <video key={item.ipfs} className='nft-video' playsInline loop autoPlay muted>
                                <source src={item.url} type="video/mp4" />
                            </video>
                        </Link>
                        <p className='nft_name'>{item.name}</p>
                        <p className='nft_price'>{item.price} neox</p>
                    </div>
                ))}
            </div>
        </TierWrapper>
    );
};

export default Tier;
