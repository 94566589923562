import ForgotPasswordWrapper from "./ForgotPassword.style";
import particleShape1 from "./../../../assets/images/icon/star_1.svg";
import particleShape2 from "./../../../assets/images/icon/star_2.svg";
import particleShape3 from "./../../../assets/images/icon/star_3.svg";
import particleShape4 from "./../../../assets/images/icon/star_4.svg";
import particleShape5 from "./../../../assets/images/icon/star_5.svg";
import particleShape6 from "./../../../assets/images/icon/star_6.svg";
import particleShape7 from "./../../../assets/images/icon/star_7.svg";
import logo from "./../../../assets/images/logo.png";
import axios from "axios";
import { useState } from 'react';
import { GoArrowLeft } from "react-icons/go";
import { toast } from 'react-toastify';
import { Link, useNavigate  } from 'react-router-dom';

import BACKEND_URL from "../../../config/config"

const ForgotPassword = () => {
    const particleShapes = [
        particleShape6,
        particleShape3,
        particleShape1,
        particleShape7,
        particleShape4,
        particleShape2,
        particleShape5,
    ];

    let navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axios.post(`${BACKEND_URL}/forgotpass`, formData)
            if (data.error) {
                return toast.error(data.error, {autoClose: 700});
            } else {
                toast.success('Successfully Updated', {autoClose: 700});
                navigate('/login');
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <ForgotPasswordWrapper className="forgotpass_wrapper">
            <div className="forgotpass-container">
                <div className="container">
                    <div className="">
                        <div className="bithu_menu_left_sect">
                            <div className="logo">
                                <Link to="/">
                                    <img src={logo} alt="bithu nft logo" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <h1 className="text_h1">Forgot Password?</h1>
                    <div className="bithu_v1_cta_content">
                        <div className="footer_stras_sect">
                            <div className="footer_stars">
                                {particleShapes?.map((shape, i) => (
                                    <span key={i} className={`star_${i + 1}`}>
                                        <img src={shape} alt="bithu nft background particle" />
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="forgotpass-wrap">
                        <form className="forgotpass-form" onSubmit={handleSubmit}>
                            <div className="sign-in-htm">
                                <div className="group">
                                    <label htmlFor="username" className="label">username</label>
                                    <input id="email" type="username" name="username" className="input" onChange={handleInputChange} value={formData.username}></input>
                                </div>
                                <div className="group">
                                    <label htmlFor="password" className="label">Password</label>
                                    <input id="password" type="password" className="input" name="password" onChange={handleInputChange} value={formData.password}></input>
                                </div>
                                <div className="group">
                                    <input type="submit" className="button" value="Save Password"></input>
                                </div>
                                <div className="hr"></div>
                                <div className="foot-lnk">
                                    <Link to="/"><a>  <GoArrowLeft className="back_icon" /> Back</a></Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ForgotPasswordWrapper>
    );
};

export default ForgotPassword;
