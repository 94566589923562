import styled from "styled-components";

const TierWrapper = styled.section`
.nft_container {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;
    padding-top: 12%;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items:center;
    gap: 3%;
}

.nft_item {
    text-align: center;
}
.nft_name {
    margin-top: 10%;
    margin-bottom: 1%;
    font-size: 20px;
}
.nft-video {
    border-radius: 10px;
    width: 90%;
    height: 90%;
    box-shadow: 0px 0 2px 0 rgba(255, 255, 255, 0.2), 1px 0px 12px 12px rgba(255, 255, 255, 0.2);
}
`;

export default TierWrapper;
