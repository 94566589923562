import MintWrapper from './Mint.style'
import axios from "axios";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useRef } from 'react';

import BACKEND_URL from "../../config/config"

const Mint = () => {

  const [data, setData] = useState([]);

  const [limit, setLimit] = useState(12);
  const [pageCount, setPageCount] = useState(1);
  const currentPage = useRef();

  const handlePageClick = (event) => {
    currentPage.current = event.selected + 1;
    getPaginationData();
    window.scrollTo(0, 0);
  }
  function getPaginationData() {
    const fetchData = async () => {
      try {
        const nfts = await axios.get(`${BACKEND_URL}/getnfts?page=${currentPage.current}&limit=${limit}`)
          .then((response) => {
            setData(response.data.resultData);
            setPageCount(response.data.pageCount);
          })
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  };
  useEffect(() => {
    currentPage.current = 1;
    getPaginationData();
  }, []);


  return (
    <MintWrapper className='mint'>
      <h2>ALL OUR HEROES</h2>
      <div className="tier_button">
        <div className='tiers'><Link to="/nft/heroes">HEROES</Link> </div>
        <div className='tiers'><Link to="/nft/nodiacs">NODIACS</Link></div>
        <div className='tiers'><Link to="/nft/omens">OMENS</Link></div>
      </div>
      <div className='nft_container'>

        {data?.map((item) => (
          <div className='nft_item'>
            <Link to={`/mint/${item.ipfs}`}>
              <video className='nft-video' playsInline loop autoPlay muted>
                <source src={item.url} type="video/mp4" />
              </video>
            </Link>
            <p className='nft_name'>{item.name}</p>
            <p className='nft_price'>{item.price} neox</p>
          </div>
        ))}
      </div>
      <div className='pagination-button'>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< Prev"
          renderOnZeroPageCount={null}
          marginPagesDisplayed={2}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
        />
      </div>
    </MintWrapper>
  );
};

export default Mint;
