import styled from "styled-components";
import sectionBgImage from "../../assets/images/bg/h1_banner_bg.png";

const WithdrawWrapper = styled.section`
    .withdraw_container{
        position: relative;
        background: url(${sectionBgImage});
        background-repeat: no-repeat;
        width: 100%;
        padding: 10% 10% 5%;
    }
    .top_content_text {
        position: relative;
        width: 100%;
        padding: 1% 10% 1% 10%;
        p {
            font-size: 20px;
        }
    }
    .top_content_header_title {
        font-size: 50px;
        font-weight: 700;
        text-align: center;
    }
    .v1_roadmap_divider {
        text-align: center;
    }
    .address_inputs {
        margin-top: 3%;
        text-align: center;
    }
    .label_group {
        display: none;
    }
    .address_group {
        width: 100%;
        margin-top: 1%;

        label {
            width: 60%;
            text: align: center;
        }
        input {
            width: 40%;
            height: 40px;
            border-radius : 5px;
            border : none;
            font-weight : 500;
            text-align: center;
            font-size: 20px;
            &: focus {
                outline: none;
            }
        }
    }
    
    .withdraw_content {
        margin-top: 1%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .address_inputs label {
        margin-right: 10px;
        color : white; 
        font-size: 20px;
    }

    .button-63 {
        align - items: center;
        background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
        border: 0;
        border-radius: 8px;
        box-sizing: border-box;
        color: #FFFFFF;
        box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
        font-family: Phantomsans, sans-serif;
        display: flex;
        justify-content: center;
        font-size: 20px;
        max-width: 100%;
        min-width: 140px;
        line-height: 1em;
        text-decoration: none;
        padding: 19px 24px;
        -webkit-user-select: none;
        user-select: none;
        white-space: nowrap;
        touch-action: manipulation;
        cursor: pointer;
    }

    .button-63:active,
    .button-63:hover {
        outline: 0;
    }

    @media (min-width: 768px) {
        .button-63 {
            font-size: 24px;
            min-width: 196px;
        }
    }

`;

export default WithdrawWrapper;
