import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.mp4";
// import { useAccount, useContractRead } from 'wagmi';
import { useState, useEffect } from "react";
// import {
//   maxSupplyCall,
//   totalMintedCall,
//   maxMintPerWalletCall,
//   publicMintCostCall,
// } from "../../../../contract/config";

const Banner = () => {
  const { mintModalHandle } = useModal();

  const [totalSupply, setTotalSupply] = useState(5555);
  const [totalMinted, setTotalMinted] = useState(5555);
  // const [remainingItem, setRemainingItem] = useState(5555);
  const [maxMintPerWallet, setMaxMintPerWallet] = useState(2);
  const [publicMintCost, setPublicMintCost] = useState(0.09);

  // const { address, isConnecting, isConnected, isDisconnected } = useAccount();

  // const { data: maxSupplyData } = useContractRead({ ...maxSupplyCall })
  // const { data: totalMintedData } = useContractRead({ ...totalMintedCall })
  // const { data: maxMintPerWalletData } = useContractRead({ ...maxMintPerWalletCall })
  // const { data: publicMintCostData } = useContractRead({ ...publicMintCostCall })

  useEffect(() => {
    // if (isConnected) {
    //   if (maxSupplyData) {
    //     setTotalSupply(maxSupplyData.toString());
    //   }
    //   if (totalMintedData) {
    //     setTotalMinted(totalMintedData.toString());
    //   }
    //   if (maxSupplyData && totalMintedData) {
    //     setRemainingItem(totalSupply - totalMinted);
    //   }
    //   if (maxMintPerWalletData) {
    //     setMaxMintPerWallet(maxMintPerWalletData.toString());
    //   }
    //   if (publicMintCostData) {
    //     setPublicMintCost(publicMintCostData.toString() / 1000000000000000000);
    //   }
    // }
  })

  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="bithu_v1_baner_left">
              <div className="border-card">
                <h2>Community based NFT collections</h2>
                <h3>
                  <span className="count">
                    <Counter end={totalMinted} duration={totalMinted} />
                  </span>{" "}
                  / {totalSupply} Minted
                </h3>
                <div className="banner_buttons">
                  <Button lg className="mint-now"
                    onClick={() => mintModalHandle()}>
                    {" "}
                    Mint now
                  </Button>

                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                <div className="bithu_v1_baner_right_img_bg">
                  <video className="home-nft" playsInline loop autoPlay muted>
                    <source src={homeImageBG} type="video/mp4" />
                  </video>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
