import { useState, useEffect } from "react";
import axios from 'axios';
import WithdrawNFTWrapper from "./WithdrawNFT.style";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import dividerImgShape from "../../assets/images/nft/v1_roadmap_divider_shape.svg";

import BACKEND_URL from "../../config/config"

const WithdrawNFT = (props) => {
    const [address, setAddress] = useState('');
    // const [balance, setBalance] = useState('');
    const [username, setUsername] = useState('');
    const [wiData, setWiData] = useState({
        wiaddress: '',
        // wiamount: '',
        username: '',
        passphrase: '',
    });
    const [nft, setNft] = useState([]);
    // const id = props;

    useEffect(() => {
        const loggedInUser = localStorage.getItem("userData");
        if (loggedInUser) {
            setAddress(JSON.parse(loggedInUser).address);
            // setBalance(JSON.parse(loggedInUser).balance);
            setUsername(JSON.parse(loggedInUser).username);
        }

        axios.post(`${BACKEND_URL}/getwithdrawNFT`, { "address": JSON.parse(loggedInUser).address }).then((response) => {
            setNft(response.data);

        })

    }, []);

    const handleChange = (e) => {
        setWiData({
            ...wiData,
            username: username,
            [e.target.name]: e.target.value
        });
    }

    const withdrawNFT = async (e) => {
        e.preventDefault();
        try {
            const withdrawnft = {
                ...wiData,
                ...nft,
                address,
            }

            const { data } = await axios.post(`${BACKEND_URL}/withdrawnft`, withdrawnft)
            if (data.error) {
                return toast.error(data.error);
            } else {
                setWiData({});
                toast.success(data.success, { autoClose: 700 });
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <WithdrawNFTWrapper className="withdraw">
            <div className="withdraw_container">
                <div className="top_content">
                    <div className="top_content_header">
                        <p className="top_content_header_title">How to Withdraw NFT</p>
                    </div>
                    <div className="top_content_text">
                        <p>
                            Access your Neoxa Wallet and enhance its security by encrypting your wallet.
                        </p><p>
                            Enter your Neoxa Wallet passphrase, then simply click the "Withdraw" button to proceed with your transaction.
                        </p>
                        <p>
                            Please ensure that the amount you are sending is less than your current balance, taking into account the applicable Neoxa chain fee
                        </p>
                    </div>
                </div>
                <div className="v1_roadmap_divider">
                    <img src={dividerImgShape} alt="bithu nft section divider" />
                </div>
                <div className="content">
                    {nft && (<div className="nft_content">
                        <div className="nft">
                            <img src={nft.url} alt=""></img>
                        </div>
                        <div className="nft_name">{nft.name}</div>
                        <div className="ipfs">{nft.ipfs}</div>
                        <div className="nft_price">{nft.price}</div>
                    </div>)}
                    <div className="address_inputs">
                        <form onSubmit={withdrawNFT}>
                            <div className="label_group">
                                <label htmlFor="address">Username</label>
                                <input type="text" name="username" id="label" readOnly value={username} />
                            </div>
                            <div className="address_group">
                                <label htmlFor="address">Current Address</label>
                                <input type="text" name="current_address" id="current_address" readOnly value={address} />
                            </div>
                            <div className="address_group">
                                <label htmlFor="address">Send Address</label>
                                <input type="text" name="wiaddress" id="send_address" onChange={handleChange} value={wiData.wiaddress} />
                            </div>
                            {/* <div className="address_group">
                                <label htmlFor="amount">Amount</label>
                                <input type="number" name="wiamount" id="amount" onChange={handleChange} value={wiData.wiamount} />
                            </div> */}
                            <div className="withdraw_content">
                                <input className="button-63" type="submit" value="Withdraw NFT" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </WithdrawNFTWrapper>
    );
};

export default WithdrawNFT;
