import { useEffect } from 'react';
import NFTMintWrapper from '../../nftmint/NFTMint.style'
import { useState } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';

import BACKEND_URL from "../../../config/config"

const AssetItems = (props) => {
    const { id } = props;
    const [item, setItem] = useState([]);
    const [balance, setBalance] = useState('');
    const [address, setAddress] = useState('');
    const [username, setUsername] = useState('');
    const [wiData, setWiData] = useState({
        wiaddress: '',
        // wiamount: '',
        username: '',
        address: '',
    });
    useEffect(() => {
        const loggedInUser = localStorage.getItem("userData");
        if (loggedInUser) {
            setAddress(JSON.parse(loggedInUser).address);
            setBalance(JSON.parse(loggedInUser).balance);
            setUsername(JSON.parse(loggedInUser).username);

        }
        // Get transactions
        const formData = JSON.parse(loggedInUser);
        axios.post(`${BACKEND_URL}/getonenft`, { 'id': id }).then((response) => {
            setItem(response.data);
        });

        axios.post(`${BACKEND_URL}/getbalance`, formData).then((response) => {
            setBalance(response.data.balance);
        });
    }, []);

    const handleChange = (e) => {
        setWiData({
            ...wiData,
            assetname: item.name,
            username: username,
            address: address,
        [e.target.name]: e.target.value
        });
    }

    const withdrawNFT = async (e) => {
        e.preventDefault();
        try {
            if (!wiData.wiaddress) {
                toast.error('Please input the correct address', { autoClose: 700 });
            }
            const { data } = await axios.post(`${BACKEND_URL}/withdrawnft`, wiData)
            if (data.error) {
                return toast.error(data.error);
            } else {
                setWiData({});
                toast.success(data.success, { autoClose: 700 });
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <NFTMintWrapper className='mint'>
            <div className='nft_container'>
                <div className="container">
                    <div className="nft_content">
                        <div className="nft_image">
                            <img src={item.url} alt="" />
                        </div>
                        <div className="right_content">
                            <div className="nft_group">
                                <label htmlFor="">- Name : </label>
                                <p className="asset_name">{item.name}</p>
                            </div>
                            <div className="nft_group">
                                <label htmlFor="">- Price : </label>
                                <p className="asset_price">{item.price} neox</p>
                            </div>
                            {/* <div className="nft_group">
                                <label htmlFor="">- Owner : </label>
                                {item.ownerAddress ? (<p className="owner_address">{item.ownerAddress}</p>) : (<p>Not exist</p>)}
                            </div> */}
                            <div className="nft_group">
                                <label htmlFor="">- IPFS : </label>
                                <p className="owner_address">{item.ipfs}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mint_button_container">
                        <div className="address_inputs">
                            <form onSubmit={withdrawNFT}>
                                <div className="label_group">
                                    <label htmlFor="address">Username</label>
                                    <input type="text" name="username" id="label" readOnly value={username} />
                                </div>
                                <div className="address_group">
                                    <label htmlFor="address">Current Address</label>
                                    <input type="text" name="current_address" id="current_address" readOnly value={address} />
                                </div>
                                <div className="address_group">
                                    <label htmlFor="address">Send Address</label>
                                    <input type="text" name="wiaddress" id="send_address" onChange={handleChange} value={wiData.wiaddress} />
                                </div>
                                <div className="withdraw_content">
                                    <input className="button-63" type="submit" value="Withdraw Now" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </NFTMintWrapper>
    );
};

export default AssetItems;
