import Layout from "../../common/layout";
import GlobalStyles from "../../assets/styles/GlobalStyles";
import Header from "../../components/section/header/v1/Header";
import Footer from "../../components/section/footer/v1";
import MyAssets from "../../components/myassets";
const MyAssetsPage = () => {

    return (
        <Layout>
            <GlobalStyles />
            <Header />
            <MyAssets />
            <Footer />
        </Layout>
    );
};

export default MyAssetsPage;
